import {Injectable} from '@angular/core';
import {TicketToDoModel} from '@ticket/models';
import {flatten} from '@core/utils/tree-utils';

export type TodoProgress =
    {
        todoTotalCount: number;
        todoDoneCount: number;
        todoDonePercent: number;
    };

@Injectable({
    providedIn: 'root'
})
export class ToDoProgressService {

    calculateProgress(todos: TicketToDoModel[]): TodoProgress {
        if (!todos) {
            return {todoDoneCount: 0, todoTotalCount: 0, todoDonePercent: 0};
        }

        // Some todos are not checkable, because they contain child todos
        const checkable = flatten(todos)
            .filter((x) => x.done !== null)
            .filter((x) => !x.children || !x.children.length);

        const doneCount = checkable
            .filter((todo) => todo.done)
            .length;

        return {todoDoneCount: doneCount, todoTotalCount: checkable.length, todoDonePercent: doneCount / checkable.length * 100};
    }
}
