import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {UserModel} from '@core/models/user/user.model';
import {AuthenticationService} from '@core/services/global/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {SubSink} from 'subsink';
import {ConfirmDialogComponentData} from '@shared/components/delete-dialog/confirm-dialog-component-data';
import {TicketCommentModel, TicketCommentType} from '@ticket/models';
import {TicketCommentHttpService} from '@ticket/ticket-detail-v2/ticket-comment/ticket-comment-http.service';
import {TicketCommentService} from '@ticket/ticket-detail-v2/ticket-comment/ticket-comment.service';
import {stringIsNullOrWhiteSpace, stripHTML} from '@core/utils/string-utils';

/**
 * List with users comments
 */
@Component({
    selector: 'omt-ticket-comment',
    templateUrl: './ticket-comment.component.html',
    styleUrls: ['./ticket-comment.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TicketCommentComponent implements OnInit, OnChanges, OnDestroy {
    /*
    *  Ticket to manage comments for.
    * */
    @Input() ticketId: number;

    get disabled(): boolean {
        return stringIsNullOrWhiteSpace(stripHTML(this.newComment.text));
    }

    isLoading = false;
    isSaving = false;
    comments: TicketCommentModel[];
    newComment = new TicketCommentModel();
    currentUser: UserModel;
    users: UserModel[];
    commentTypes = [
        {name: this.translate.instant('TICKET.VISIT_REPORT'), value: TicketCommentType.VisitReport},
        {name: this.translate.instant('TICKET.NOTE'), value:  TicketCommentType.Note},
        {name: this.translate.instant('TICKET.TASK'), value:  TicketCommentType.Task},
        {name: this.translate.instant('TICKET.RESUBMISSION'), value:  TicketCommentType.Resubmission},
        {name: this.translate.instant('TICKET.PHONE_ENTRY'), value:  TicketCommentType.PhoneEntry},
        {name: this.translate.instant('TICKET.EMAIL_RESPONSE'), value:  TicketCommentType.EmailResponse},
    ];
    //commentType = TicketCommentType.Note;
    dialogData = new ConfirmDialogComponentData(this.translate.instant('GLOBAL.DELETE'), this.translate.instant('TICKET.DELETE_COMMENT_CONFIRMATION_TEXT'));
    private subs = new SubSink();

    /*get internExternDisabled(): boolean {
        return this.newComment.type === TicketCommentType.EmailResponse;
    }*/

    /*--------------------------------------------------------------
    # Lifecycle
    --------------------------------------------------------------*/

    constructor(
        private readonly commentHttpService: TicketCommentHttpService,
        private readonly commentService: TicketCommentService,
        public authenticationService: AuthenticationService,
        private readonly translate: TranslateService
    ) {
    }

    ngOnInit(): void {
        this.currentUser = {...this.authenticationService.currentUser};
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.ticketId) {
            this.loadComments();
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*--------------------------------------------------------------
    # Actions
    --------------------------------------------------------------*/

    /**
     * Adds a comment
     */
    addComment(): void {
        if (this.disabled) {
            return;
        }

        this.newComment.userName = this.currentUser.username;
        this.newComment.ticketId = this.ticketId;
        this.isSaving = true;

        // Create new comment on API
        this.subs.sink = this.commentHttpService.create(this.newComment).subscribe((x) => {
            this.newComment = new TicketCommentModel();
            this.comments = [x, ...this.comments];
            this.isSaving = false;
        });
    }

    delete(commentId: number): void {
        this.isSaving = true;

        this.subs.sink = this.commentHttpService.delete(commentId).subscribe(() => {
            this.isSaving = false;
            this.removeComment(commentId);
        });
    }

    /**
     * Removed comment
     *
     * @param commentId Id of comment
     */
    removeComment(commentId: number): void {
        this.comments = [...this.comments.filter((x) => x.id !== commentId)];
    }

    /**
     * Updated comment
     *
     * @param comment Instance of TicketCommentModel class
     */
    update(comment: TicketCommentModel): void {
        this.subs.sink = this.commentHttpService.update(comment).subscribe();
    }

    getCreatorName = (comment: TicketCommentModel): string => this.commentService.getCommentCreator(comment);

    /*--------------------------------------------------------------
    # Load
    --------------------------------------------------------------*/
    /**
     * Loads comment
     */
    loadComments(): void {
        if (!this.ticketId) {
            return;
        }

        this.isLoading = true;
        this.subs.sink = this.commentHttpService.getByTicketId(this.ticketId).subscribe((data: TicketCommentModel[]) => {
            this.comments = [...data];
            this.isLoading = false;
        });
    }

    protected readonly TicketCommentType = TicketCommentType;
}
