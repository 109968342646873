export class TicketDetailState {
    creationMode = true;
    editMode = false;
    isLoading = false;
    isSaving = false;

    canDelete = false;
    canCancelEditMode = false;
    canLinkBoards = false;
    canSeeDeductionDuration = false;
    isCurrentTicket = false;
    templateMode = false;
}
