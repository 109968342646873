import {A11yModule} from '@angular/cdk/a11y';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ExtendedModule, FlexModule} from '@ngbracket/ngx-layout';
import {FormsModule} from '@angular/forms';
import {MatBadgeModule} from '@angular/material/badge';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {CoreModule} from '@core/core.module';
import {CustomerModule} from '@customer/customer.module';
import {ProjectModule} from '@management/tickets/project/project.module';
import {NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TicketV2RoutingModule} from '@ticket/ticket-v2-routing.module';
import {PendingWorklogDialogComponent} from '@ticket/pending-worklogs/pending-worklog-dialog/pending-worklog-dialog.component';
import {BoardsModule} from '@boards/boards.module';
import {TicketDialogComponent} from '@ticket/ticket-dialog/ticket-dialog.component';
import {PendingWorklogsComponent} from '@ticket/pending-worklogs/pending-worklogs.component';
import {SharedModule} from '@shared/shared.module';
import {DesignerModule} from '@reporting/designerV2/designer.module';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {SaveButtonModule} from '@libs/ui/src/components/save-button/save-button.module';
import {DeleteIconModule} from '@core/components/delete-icon/delete-icon.module';
import {SpinnerModule} from '@libs/ui/src/components/spinner/spinner.module';
import {CustomerLinkModule} from '@core/components/customer-link/customer-link.module';
import {DatePickerModule} from '@core/components/datepicker/date-picker.module';
import {HeaderModule} from '@core/components/header/header.module';
import {SearchInputModule} from '@libs/ui/src/components/search-input/search-input.module';
import {TimeSelectModule} from '@core/components/time-select/time-select.module';
import {TicketAttachmentModule} from '@ticket/ticket-attachment/ticket-attachment.module';
import {TicketTypeModule} from '@ticket/ticket-type/ticket-type.module';
import {TicketTodoModule} from '@ticket/ticket-todo/ticket-todo.module';
import {WorkLogModule} from '@ticket/work-log/work-log.module';
import {EditorModule} from '@core/components/editor/editor.module';
import {AvatarModule} from '@libs/ui/src/components/avatar/avatar.module';
import {DialogHostModule} from '@libs/ui/src/components/dialog-host/dialog-host.module';
import {MessageBoxModule} from '@libs/ui/src/components/message-box/message-box.module';
import {TicketSearchDialogModule} from '@core/components/ticket-search-dialog/ticket-search-dialog.module';
import {TicketCommentComponent} from '@ticket/ticket-detail-v2/ticket-comment/ticket-comment.component';
import {TicketProgressBarComponent} from './ticket-progress-bar/ticket-progress-bar.component';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {BusinessUnitDropdownModule} from '@shared/components/dropdowns/business-unit-dropdown/business-unit-dropdown.module';
import {CustomerDropdownModule} from '@customer/components/dropdowns/customer-dropdown/customer-dropdown.module';
import {CustomerRefDropdownModule} from '@customer/components/dropdowns/customer-ref-dropdown/customer-ref-dropdown.module';
import {ProjectDropdownModule} from '@app/project-management/project-dropdown/project-dropdown.module';
import {TicketTemplateModule} from '@ticket/dropdowns/ticket-template/ticket-template.module';
import {TicketTypeDropdownModule} from '@ticket/dropdowns/ticket-type-dropdown/ticket-type-dropdown.module';
import {PriorityDropdownModule} from '@shared/components/dropdowns/priority/priority-dropdown.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {TimeSpanInputModule} from '@core/components/time-span-input/time-span-input.module';
import {FavoriteButtonComponent} from '@core/components/favorites/favorite-button.component';
import {ToDoProgressBarComponent} from '@ticket/ticket-todo/to-do-progress-bar/to-do-progress-bar.component';
import {
    CustomerRefPersonDropdownComponent
} from '@customer/components/dropdowns/customer-ref-person-dropdown/customer-ref-person-dropdown.component';

@NgModule({
    declarations: [TicketCommentComponent, TicketDialogComponent, PendingWorklogsComponent, PendingWorklogDialogComponent, TicketProgressBarComponent],
    imports: [
        TicketV2RoutingModule,
        CommonModule,
        CoreModule,
        MatSortModule,
        MatPaginatorModule,
        MatTableModule,
        FlexModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        ProjectModule,
        CustomerModule,
        MatTabsModule,
        NgSelectModule,
        FormsModule,
        MatInputModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatChipsModule,
        MatTooltipModule,
        ExtendedModule,
        BoardsModule,
        MatMenuModule,
        MatDatepickerModule,
        MatBadgeModule,
        MatToolbarModule,
        NgbCollapseModule,
        TranslateModule,
        A11yModule,
        ScrollingModule,
        DragDropModule,
        SharedModule,
        DesignerModule,
        MatSlideToggleModule,
        AvatarModule,
        SaveButtonModule,
        DeleteIconModule,
        SpinnerModule,
        CustomerLinkModule,
        DatePickerModule,
        HeaderModule,
        SearchInputModule,
        TimeSelectModule,
        TicketAttachmentModule,
        TicketTypeModule,
        TicketTodoModule,
        WorkLogModule,
        EditorModule,
        DialogHostModule,
        MessageBoxModule,
        TicketSearchDialogModule,
        MatProgressBarModule,
        BusinessUnitDropdownModule,
        CustomerDropdownModule,
        CustomerRefDropdownModule,
        PriorityDropdownModule,
        TicketTypeDropdownModule,
        TicketTemplateModule,
        ProjectDropdownModule,
        FuseSharedModule,
        TimeSpanInputModule,
        FavoriteButtonComponent,
        ToDoProgressBarComponent,
        CustomerRefPersonDropdownComponent
    ],
    exports: [BoardsModule, TicketDialogComponent, TicketCommentComponent, TicketProgressBarComponent]
})
export class TicketV2Module {
}
