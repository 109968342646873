import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BusinessUnitService} from '@core/services/global/business-unit.service';
import {EntityDropdown} from '@core/components/entity-dropdown/entity-dropdown.component';
import {TicketObserverModel} from '@ticket/models';
import {BusinessUnitModel} from '@core/models/businessunit/business-unit.model';
import {AuthenticationService} from '@core/services/global/authentication.service';

@Component({
    selector: 'omt-ticket-observer-dropdown',
    templateUrl: './ticket-observer-dropdown.component.html',
    styleUrls: ['./ticket-observer-dropdown.component.scss']
})
export class TicketObserverDropdownComponent extends EntityDropdown implements OnInit, OnDestroy {
    @Input() observers: TicketObserverModel[] = [];
    @Output() observersChange = new EventEmitter<TicketObserverModel[]>();

    availableObservers: TicketObserverModel[] = [];
    businessUnits: BusinessUnitModel[];

    constructor(private readonly businessUnitService: BusinessUnitService,
                private readonly authService: AuthenticationService) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        this.subs.sink = this.businessUnitService.getAllBusinessUnits()
            .subscribe((bus) => this.businessUnits = [...bus]);

        this.subs.sink = this.businessUnitService.getObservers()
            .subscribe((observers) => this.availableObservers = [...observers]);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    trackByFn(item: TicketObserverModel): number {
        return item.businessUnit?.id;
    }

    takeObservation(): void {
        const ownBusinessUnit = this.businessUnits.find((x) => x.userId === this.authService.currentUser.id);

        if (!ownBusinessUnit) {
            return;
        }

        if (!this.observers) {
            this.observers = [];
        }

        const observer = this.availableObservers.find((x) => x.businessUnit.id === ownBusinessUnit.id);
        const alreadyObserving = this.observers.find((x) => x === observer || x.businessUnit.id === ownBusinessUnit.id) != null;

        if (!alreadyObserving) {
            this.observers = [...this.observers, observer];
        }

        this.observersChange.emit(this.observers);
    }
}
