import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TicketTypeService} from '@management/tickets/ticket-types/ticket-type.service';
import {TicketTypeModel} from '@ticket/models';
import {tap} from 'rxjs/operators';
import {TicketTypeDialogComponent} from '@management/tickets/ticket-types/ticket-type-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {EntityDropdown} from '@core/components/entity-dropdown/entity-dropdown.component';

@Component({
    selector: 'omt-ticket-type-dropdown',
    templateUrl: './ticket-type-dropdown.component.html',
    styleUrls: ['./ticket-type-dropdown.component.scss']
})
export class TicketTypeDropdownComponent extends EntityDropdown {
    @Input() ticketType: TicketTypeModel;
    @Output() ticketTypeChange = new EventEmitter<TicketTypeModel>();
    @Output() loaded = new EventEmitter<TicketTypeModel[]>();

    ticketTypes$ = this.ticketTypeService.getTicketTypes().pipe(tap((types) => this.loaded.emit(types)));

    constructor(private readonly ticketTypeService: TicketTypeService,
                private readonly dialog: MatDialog) {
        super();
    }

    emit(): void {
        this.ticketTypeChange.emit(this.ticketType);
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(TicketTypeDialogComponent, {
            width: '750px',
            disableClose: true,
            panelClass: ['mat-dialog-overflow', 'dialog-0-p']
        });

        this.subs.sink = dialogRef.componentInstance.newTicketType.pipe(
            tap((newType) => {
                this.ticketType = newType;
                this.emit();
            })
        ).subscribe(() => this.ticketTypes$ = this.ticketTypeService.getTicketTypes(true));
    }
}
