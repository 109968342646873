import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TicketDetailState} from '@ticket/ticket-detail-v2/ticket-detail-state';
import {TicketModel} from '@ticket/models';
import {TicketTimeTrackingData} from '@ticket/ticket-detail-v2/ticket-time-tracking-data';

@Component({
    selector: 'omt-ticket-time-tracking',
    templateUrl: './ticket-time-tracking.component.html',
    styleUrls: ['./ticket-time-tracking.component.scss']
})
export class TicketTimeTrackingComponent {
    @Input() state: TicketDetailState;
    @Input() ticket: TicketModel;
    @Input() timeTrackingData: TicketTimeTrackingData;
    @Output() timeChange = new EventEmitter<{ timeStr: string; field: string }>();

    setTime(timeStr: string, field: string): void {
        this.timeChange.emit({timeStr, field});
    }
}
