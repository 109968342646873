<ng-select
  #ngSelect
  [(ngModel)]="ticketType"
  (ngModelChange)="emit()"
  bindLabel="name"
  [placeholder]="'TICKET.TICKET_TYPE' | translate"
  [items]="ticketTypes$ | async"
  class="redesign entity-dropdown"
  [clearable]="false"
  appendTo="body"
  required>
  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-label="label">
    <omt-ticket-type [ticketType]="item"></omt-ticket-type>
  </ng-template>

  <ng-template ng-footer-tmp *omtHasPermission="'ticketVerwaltung'">
    <mat-card class="w-100-p" fxLayout="column" fxLayoutAlign="center center">
      <button mat-mini-fab matTooltip="Neue Ticketart erstellen" class="rounded" (click)="openDialog(); ngSelect.close()">
        <i class="fas fa-plus"></i>
      </button>
    </mat-card>
  </ng-template>
</ng-select>
