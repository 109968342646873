<ng-select
  #ngSelect
  [items]="customerRefPersons"
  [disabled]="disabled"
  [virtualScroll]="true"
  [(ngModel)]="value"
  (ngModelChange)="onValueChange($event)"
  [loading]="isLoading"
  class="entity-dropdown"
  bindLabel="name"
  [placeholder]="'CUSTOMER.CONTACT_PERSON' | translate"
  [notFoundText]="'TICKET.NOT_FOUND_TEXT' | translate"
  appendTo="body"
>
  <ng-template let-item="item" let-label="label" ng-label-tmp ng-option-tmp>
    <div fxLayout="row" fxLayoutAlign="start center">
      <omt-avatar [avatarUrl]="item.avatar ?? item.avatarUrl" style="margin-right: 1rem; flex-shrink: 0"/>
      <span>{{ item.firstName }} {{ item.lastName }}</span>
    </div>
  </ng-template>

  <ng-template ng-footer-tmp *omtHasPermission="'kunden'">
    <omt-dropdown-footer [tooltip]="'CUSTOMER.CREATE_CONTACT_PERSON' | translate" (addClick)="openDialog(); ngSelect.close()"></omt-dropdown-footer>
  </ng-template>

  <ng-template ng-notfound-tmp>
    <omt-dropdown-no-entries></omt-dropdown-no-entries>
  </ng-template>
</ng-select>
