<omt-dialog-host title="Als Vorlage speichern" (closeClicked)="close()">
  <div dialog-content class="p-2" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="11px">
    <label>Geben Sie einen Namen für die Vorlage an</label>
    <input [(ngModel)]="name" placeholder="Name der Vorlage" type="text" (keyup.enter)="save()" class="redesign w-300"/>
  </div>

  <div dialog-footer class="modal-actions">
    <button color="primary" mat-fab [disabled]="!name || name === ''" (click)="save()">
      <i class="fas fa-check fa-2x"></i>
    </button>
  </div>
</omt-dialog-host>
