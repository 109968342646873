import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerLinkComponent} from '@core/components/customer-link/customer-link.component';
import {AvatarModule} from '@libs/ui/src/components/avatar/avatar.module';
import {FlexModule} from '@ngbracket/ngx-layout';
import {RouterModule} from '@angular/router';
import {CoreModule} from '@core/core.module';


@NgModule({
    declarations: [CustomerLinkComponent],
    imports: [
        CommonModule,
        AvatarModule,
        FlexModule,
        RouterModule,
        CoreModule
    ],
    exports: [
        CustomerLinkComponent
    ]
})
export class CustomerLinkModule {
}
