import {Component, Input} from '@angular/core';
import {TicketDetailState} from '@ticket/ticket-detail-v2/ticket-detail-state';
import {TicketModel} from '@ticket/models';

@Component({
    selector: 'omt-ticket-dates',
    templateUrl: './ticket-dates.component.html',
    styleUrls: ['./ticket-dates.component.scss']
})
export class TicketDatesComponent {
    @Input() state: TicketDetailState;
    @Input() ticket: TicketModel;
}
