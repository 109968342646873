<mat-card class="detail-card p-0 rounded">
  <div class="section-header">
    <i class="fas fa-money-check-alt"></i>
    Budget
  </div>
  @if (budgetInfo) {
    <mat-card-content class="p-1" fxLayout="column" fxLayoutGap="15px">
      <div class="progress w-100-p mat-elevation-z1">
        <div
          class="progress-bar cursor-pointer"
          [ngClass]="{'bg-cyan': budgetInfo.percentage <= 75, 'background-red': budgetInfo.percentage > 75}"
          role="progressbar"
          [style.width.%]="budgetInfo.percentage"
          aria-valuenow="15"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
          <label> {{ 'TICKET.USED_BUDGET' | translate }}</label>
          <span>{{ budgetInfo.usedBudget | currency: '€' }}</span>
        </div>
        @if (state.editMode || ticket.maximumBudget) {
          <div fxLayout="column">
            <omt-form-element [label]="'TICKET.MAXIMUM_BUDGET' | translate">
              @if (!state.editMode) {
                <span>{{ ticket.maximumBudget | currency: '€' }}</span>
              } @else {
                <div class="input-wrapper" data-symbol="€">
                  @if (state.editMode) {
                    <input type="number" step="0.01" class="form-control redesign" [(ngModel)]="ticket.maximumBudget"/>
                  }
                </div>
              }
              <ng-template #maximumBudget>
                <div class="input-wrapper" data-symbol="€">
                  @if (state.editMode) {
                    <input type="number" step="0.01" class="form-control redesign" [(ngModel)]="ticket.maximumBudget"/>
                  }
                </div>
              </ng-template>
            </omt-form-element>
          </div>
        }
      </div>
    </mat-card-content>
  }
</mat-card>
