import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TicketDetailState} from '@ticket/ticket-detail-v2/ticket-detail-state';
import {TicketModel, TicketWorklogModel} from '@ticket/models';

@Component({
    selector: 'omt-ticket-worklogs',
    templateUrl: './ticket-worklogs.component.html',
    styleUrls: ['./ticket-worklogs.component.scss']
})
export class TicketWorklogsComponent {
    @Input() state: TicketDetailState;
    @Input() ticket: TicketModel;
    @Output() updatedWorkLogs = new EventEmitter();

    updateWorkLogs(updatedLogs: TicketWorklogModel[]): void {
        this.ticket.workLogs = [...updatedLogs];
        this.updatedWorkLogs.emit();
    }
}
