import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {TicketTypeModule} from '@ticket/ticket-type/ticket-type.module';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {FlexModule} from '@ngbracket/ngx-layout';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {CoreModule} from '@core/core.module';
import {TicketTypeDropdownComponent} from '@ticket/dropdowns/ticket-type-dropdown/ticket-type-dropdown.component';


@NgModule({
    declarations: [TicketTypeDropdownComponent],
    exports: [
        TicketTypeDropdownComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        NgSelectModule,
        FormsModule,
        TicketTypeModule,
        MatCardModule,
        FlexModule,
        MatButtonModule,
        MatTooltipModule,
        TranslateModule
    ]
})
export class TicketTypeDropdownModule {
}
