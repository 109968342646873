<div class="ticket-observer-dropdown-container" fxLayout="row" fxLayoutAlign="start center">
  <ng-select
    [(ngModel)]="observers"
    (ngModelChange)="observersChange.emit(observers)"
    [trackByFn]="trackByFn"
    bindLabel="businessUnit.displayName"
    groupBy="type"
    [items]="availableObservers"
    [multiple]="true"
    (clear)="clear.emit()"
    placeholder="Ticket-Beobachter"
    class="entity-dropdown redesign"
    fxFlex="1 1 auto"
    appendTo="body">
  </ng-select>
  <button matTooltip="Selbst zuweisen" (click)="takeObservation()" mat-icon-button [disabled]="!businessUnits">
    <i class="far fa-handshake"></i>
  </button>
</div>
