import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {ConfirmDialogComponentData} from '@shared/components/delete-dialog/confirm-dialog-component-data';
import {TranslateService} from '@ngx-translate/core';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {DialogService} from '@core/services/global/dialog.service';
import {TicketPendingWorklogModel} from '@ticket/models';
import {UserSettingsService} from '@core/services/user-settings.service';
import {CurrentTicketService} from '@ticket/current-ticket-switcher/current-ticket.service';
import {StorageKeyHelper} from '@core/models/storage-key-helper';
import {HeaderService} from '@core/components/header/header.service';
import {SubSink} from 'subsink';
import {filter, switchMap} from 'rxjs/operators';

@Component({
    selector: 'omt-pending-worklogs',
    templateUrl: './pending-worklogs.component.html',
    styleUrls: ['./pending-worklogs.component.scss']
})
export class PendingWorklogsComponent implements OnInit, OnDestroy {
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    dataSource = new MatTableDataSource();
    displayedColumns = ['ticketKey', 'ticketName', 'from', 'to', 'text', 'actions'];

    pendingWorklogs: TicketPendingWorklogModel[];

    private subs = new SubSink();

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle
    // -----------------------------------------------------------------------------------------------------

    constructor(
        private readonly userSettingsService: UserSettingsService,
        private readonly dialogService: DialogService,
        private readonly pendingWorklogService: CurrentTicketService,
        private readonly translateService: TranslateService,
        private readonly headerService: HeaderService) {
    }

    ngOnInit(): void {
        this.headerService.setTitle('TICKET.OUTSTANDING_WORKING_HOURS', true);
        this.subs.sink = this.pendingWorklogService.currentTicketInfo.subscribe(() => {
            this.loadPendingWorkLogs();
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    loadPendingWorkLogs(): void {
        this.subs.sink = this.pendingWorklogService.getPendingWorkLogs().subscribe((value) => {
            this.pendingWorklogs = value;
            this.tableConfiguration();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Actions
    // -----------------------------------------------------------------------------------------------------

    setPageSize(ev: any): void {
        const pageSize = ev.pageSize;
        this.userSettingsService.setPageSize(StorageKeyHelper.PENDING_WORK_LOG_PAGE_SIZE_KEY, pageSize);
    }

    getPageSize(): number {
        return this.userSettingsService.getPageSize(StorageKeyHelper.PENDING_WORK_LOG_PAGE_SIZE_KEY);
    }

    tableConfiguration(): void {
        this.dataSource.sortingDataAccessor = (item: TicketPendingWorklogModel, property) => {
            switch (property) {
                case 'ticketKey':
                    return item.ticket.ticketKey;
                case 'ticketName':
                    return item.ticket.summary;
                case 'from':
                    return item.start;
                case 'to':
                    return item.end;
                case 'text':
                    return item.comment;
                default:
                    return item[property];
            }
        };

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        setTimeout(() => this.dataSource.data = this.pendingWorklogs, 1);
    }

    openDialog(pendingWorklogModel: TicketPendingWorklogModel): void {
        this.dialogService.openPendingWorklogDialog(pendingWorklogModel);
    }

    openDeleteDialog(pendingWorklogModel: TicketPendingWorklogModel): void {
        const dialogRef = this.dialogService.openConfirmDialog(new ConfirmDialogComponentData(this.translateService.instant('GLOBAL.DELETE'),
            this.translateService.instant('TICKET.PENDING_WORKLOG_DELETE_CONFIRMATION_TEXT')));

        this.subs.sink = dialogRef.componentInstance.deleteClicked
            .pipe(
                filter((x) => x),
                switchMap(() => this.pendingWorklogService.deletePendingWorkLog(pendingWorklogModel.id))
            )
            .subscribe();
    }
}
