import {Component, OnDestroy, OnInit} from '@angular/core';
import {TicketAttachmentModel, TicketBudgetInfoModel, TicketModel} from '@ticket/models';
import {ConfirmDialogComponentData} from '@shared/components/delete-dialog/confirm-dialog-component-data';
import {DialogService} from '@core/services/global/dialog.service';
import {TicketHttpService} from '@ticket/ticket-http.service';
import {SubSink} from 'subsink';
import {filter, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Location} from '@angular/common';
import {TicketRequest} from '@reporting/designerV2/model/reporting.model.request';
import {ReportingDialogComponent} from '@reporting/designerV2/components/reporting-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CurrentTicketService} from '@ticket/current-ticket-switcher/current-ticket.service';
import {HeaderService} from '@core/components/header/header.service';
import {UserSettingsService} from '@core/services/user-settings.service';
import {UserSettingsModel} from '@core/models/user/user-settings.model';
import {TicketDetailService} from '@ticket/ticket-detail-v2/ticket-detail.service';
import {ActivatedRoute} from '@angular/router';
import {TicketDetailState} from '@ticket/ticket-detail-v2/ticket-detail-state';
import {AuthenticationService} from '@core/services/global/authentication.service';
import {TicketService} from '@ticket/ticket.service';
import {WorklogComponentData} from '@ticket/work-log/worklog/worklog-dialog.data.model';
import {BusinessUnitModel} from '@core/models/businessunit/business-unit.model';
import {BusinessUnitService} from '@core/services/global/business-unit.service';
import {BoardHttpService} from '@boards/board-http.service';
import {tapOnce} from '@core/utils/custom-operators';
import {BoardModel} from '@boards/models';
import {TicketTimeTrackingData} from '@ticket/ticket-detail-v2/ticket-time-tracking-data';
import {TicketTimeTrackingDataService} from '@ticket/ticket-detail-v2/ticket-time-tracking-data.service';
import {TicketWorkLogHttpService} from '@ticket/work-log/worklog/ticket-work-log-http.service';
import {UserRoleModel} from '@core/models/user/userrole.model';
import {getTimeFromTimeString} from '@core/utils/date-utils';

@Component({
    selector: 'omt-ticket-detail-v2',
    templateUrl: './ticket-detail-v2.component.html',
    styleUrls: ['./ticket-detail-v2.component.scss']
})
export class TicketDetailV2Component implements OnInit, OnDestroy {
    ticket: TicketModel;
    template$ = this.ticketDetailService.template$;
    businessUnits: BusinessUnitModel[] = [];
    linkedBoards$: Observable<BoardModel[]>;
    settings: UserSettingsModel;
    boardsToLink: number[] = [];
    timeTrackingData = new TicketTimeTrackingData();
    budgetInfo$ = of(new TicketBudgetInfoModel());

    private _state: TicketDetailState = new TicketDetailState();
    private subs = new SubSink();

    get state(): TicketDetailState {
        return this._state;
    }

    get roles(): UserRoleModel {
        return this.authService.currentUser.userRoles;
    }

    constructor(private readonly ticketDetailService: TicketDetailService,
                private readonly ticketService: TicketService,
                private readonly ticketTimeTrackingDataService: TicketTimeTrackingDataService,
                private readonly boardService: BoardHttpService,
                private readonly authService: AuthenticationService,
                private readonly headerService: HeaderService,
                private readonly userSettingsService: UserSettingsService,
                private readonly activatedRoute: ActivatedRoute,
                private readonly ticketHttpService: TicketHttpService,
                private readonly workLogHttpService: TicketWorkLogHttpService,
                private readonly dialogService: DialogService,
                private readonly dialog: MatDialog,
                private readonly businessUnitService: BusinessUnitService,
                private readonly location: Location) {
    }

    ngOnInit(): void {
        this.setHeaderConfig();
        this.subs.sink = this.headerService.routeConfigLoaded$.subscribe(() => this.setHeaderConfig());
        this.subs.sink = this.ticketDetailService.ticket$
            .pipe(filter((x) => x != null),
                tapOnce(() => this.loadBusinessUnits()),
                tap((ticket) => {
                    if (!ticket.id) {
                        return;
                    }

                    this.linkedBoards$ = this.boardService.readLinkedBoardsByTicketId(ticket.id).pipe(tap((data) => this.boardsToLink = data.map((x) => x.id)));
                    this.budgetInfo$ = this.ticketHttpService.getTicketBudgetInfo(ticket.id);
                }))
            .subscribe((ticket) => {
                this.ticket = {...ticket};
                this.refreshTimeTrackingData();
            });
        this.subs.sink = this.ticketDetailService.state$.subscribe((newState) => this._state = {...newState});
        this.subs.sink = this.ticketDetailService.title$.subscribe((newTitle) => this.headerService.setTitle(newTitle));
        this.subs.sink = this.userSettingsService.userSettings.subscribe((x) => this.settings = x);
        this.subs.sink = this.activatedRoute.data.subscribe((routeData) => this.parseRouteParams(routeData));
        this.subs.sink = this.workLogHttpService.newWorkLog.subscribe(() => this.refreshData());
        this.subs.sink = this.workLogHttpService.updatedWorkLog.subscribe(() => this.refreshData());
    }

    ngOnDestroy(): void {
        this.ticketDetailService.reset();
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Load
    // -----------------------------------------------------------------------------------------------------

    loadBusinessUnits(): void {
        this.subs.sink = this.businessUnitService.getAllBusinessUnits()
            .pipe(tap((data: BusinessUnitModel[]) => this.setDefaultAssignee(data)))
            .subscribe((data) => this.businessUnits = data);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Business
    // -----------------------------------------------------------------------------------------------------

    createTicket(): void {
        this.ticketDetailService.create(this.ticket, this.boardsToLink);
    }

    updateTicket(): void {
        this.ticketDetailService.update(this.ticket, this.boardsToLink);
    }

    deleteTicket(): void {
        const dialogData = new ConfirmDialogComponentData('Bestätigen', 'Wollen Sie dieses Ticket wirklich löschen? Es kann nicht wiederhergestellt werden.');
        const dialogRef = this.dialogService.openConfirmDialog(dialogData);

        this.subs.sink = dialogRef.componentInstance.deleteClicked
            .subscribe((clicked) => clicked ? this.subs.sink = this.ticketHttpService.delete(this.ticket.id).subscribe(() => this.location.back()) : void (0));
    }

    saveAsTemplate(): void {
        this.ticketDetailService.saveAsTemplate(this.ticket);
    }

    cloneTicket(): void {
        this.ticketDetailService.cloneTicket(this.ticket);
        this.timeTrackingData = new TicketTimeTrackingData();
        this.budgetInfo$ = of(new TicketBudgetInfoModel());
    }

    addAttachments(ticketAttachmentModel: TicketAttachmentModel): void {
        if (this.ticket.id) {
            return;
        }

        if (!this.ticket.ticketAttachments) {
            this.ticket.ticketAttachments = [];
        }

        this.ticket.ticketAttachments.push(ticketAttachmentModel);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Ticket Start / Stop
    // -----------------------------------------------------------------------------------------------------

    stopCurrentTicket(): void {
        this.ticketDetailService.stopCurrentTicket();
    }

    startCurrentTicket(): void {
        this.ticketDetailService.startCurrentTicket();
    }

    pauseCurrentTicket(): void {
        this.ticketDetailService.pauseCurrentTicket();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ View
    // -----------------------------------------------------------------------------------------------------

    setTime(timeStr: string, field: string): void {
        if (!this.ticket.hasOwnProperty(field)) {
            console.error(`${field} is not a valid field of Ticket`);
        }

        this.ticket[field] = getTimeFromTimeString(timeStr);

        this.refreshTimeTrackingData();
    }

    setHeaderConfig(): void {
        this.headerService.config = {
            faIcon: 'fas fa-chevron-left',
            navigateBackOnClick: true
        };
    }

    refreshTimeTrackingData(): void {
        this.timeTrackingData = this.ticketTimeTrackingDataService.processTimeTrackingData(this.ticket, this.ticket.workLogs);
    }

    toggleEditMode(): void {
        this.ticketDetailService.toggleEditMode();
    }

    takeResponsibility(): void {
        this.ticket.assignee = this.businessUnits.find((x) => x.userId === this.authService.currentUser.id);
        this.ticket.assigneeId = this.ticket.assignee.id;
    }

    refreshData(): void {
        this.refreshTimeTrackingData();

        if (this.ticket.id) {
            this.budgetInfo$ = this.ticketHttpService.getTicketBudgetInfo(this.ticket.id);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Dialogs
    // -----------------------------------------------------------------------------------------------------

    workLogDialog(ticketId: number): void {
        const data = new WorklogComponentData();
        data.ticketId = ticketId;
        this.dialogService.openWorklogDialog(data);
    }

    openReportDialog(): void {
        const req = new TicketRequest(null, this.ticket.id);

        ReportingDialogComponent.open(this.dialog, req);
    }

    changeAssignee(newAssignee: BusinessUnitModel): void {
        this.ticket.assignee = newAssignee;
        this.ticket.assigneeId = newAssignee?.id;
    }

    private setDefaultAssignee(data: BusinessUnitModel[]): void {
        if (this.state.templateMode || this.ticket.id || !data) {
            return;
        }

        this.ticket.assignee = data.find((bu) => bu.userId === this.authService.currentUser.id);
        this.ticket.assigneeId = this.ticket.assignee.id;
    }

    private parseRouteParams(routeData): void {
        this.subs.sink = this.activatedRoute.params.subscribe((params) => {
            const key = params.id;

            this.ticketDetailService.load(key, routeData.templateMode);
        });
    }
}
