@if (todoProgress) {
  <div fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="start center">
    @if (showText) {
      <span class="to-do-progress-info">{{ todoProgress.todoDoneCount }} / {{ todoProgress.todoTotalCount }}</span>
    }
    @if (showProgressBar) {
      <mat-progress-bar class="rounded mat-elevation-z2 to-do-progress-bar"
                        color="primary"
                        [value]="todoProgress.todoDonePercent"
                        [matTooltip]="'MANAGEMENT.PROGRESS' | translate"/>
    }
  </div>
}
