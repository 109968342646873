import {Injectable} from '@angular/core';
import {TicketModel, TicketTypeModel} from '@ticket/models';
import {BehaviorSubject} from 'rxjs';
import {TicketDetailState} from '@ticket/ticket-detail-v2/ticket-detail-state';
import {TicketHttpService} from '@ticket/ticket-http.service';
import {TranslateService} from '@ngx-translate/core';
import {CurrentTicketService} from '@ticket/current-ticket-switcher/current-ticket.service';
import {AuthenticationService} from '@core/services/global/authentication.service';
import {TicketTemplateCreateRequest, TicketTemplateModel, TicketTemplateUpdateRequest} from '@management/tickets/template/ticket.template.model';
import {TicketTemplateHttpService} from '@management/tickets/template/ticket-template-http.service';
import {TicketTemplateService} from '@management/tickets/template/ticket-template.service';
import {Location} from '@angular/common';
import {SnackbarService} from '@core/services/snackbar.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import {SubSink} from 'subsink';
import {TicketService} from '@ticket/ticket.service';
import {UserRoleModel} from '@core/models/user/userrole.model';
import {TicketSwitchStopDialogData} from '@ticket/current-ticket-switcher/ticket-switch-stop-dialog/ticket-switch-stop-dialog-data';
import {DialogService} from '@core/services/global/dialog.service';
import {BoardHttpService} from '@boards/board-http.service';
import {DEFAULT_PRIORITY} from '@shared/components/dropdowns/priority/default-priority';
import {TicketTemplateNameDialogComponent} from '@ticket/dropdowns/ticket-template/ticket-template-name-dialog/ticket-template-name-dialog.component';
import {stringIsNullOrWhiteSpace} from '@core/utils/string-utils';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TicketDetailService {
    private ticket = new BehaviorSubject<TicketModel>(null);
    ticket$ = this.ticket.asObservable();

    private get currentTicket(): TicketModel {
        return this.ticket.getValue();
    }

    private set currentTicket(ticket: TicketModel) {
        this.tempTicket = ticket;
        this.ticket.next(ticket);
    }

    private template = new BehaviorSubject<TicketTemplateModel>(null);
    template$ = this.template.asObservable();

    private state = new BehaviorSubject<TicketDetailState>(new TicketDetailState());
    state$ = this.state.asObservable();

    get currentState(): TicketDetailState {
        return this.state.getValue();
    }

    private title = new BehaviorSubject<string>('Ticketdetail');
    title$ = this.title.asObservable();

    private tempTicket: TicketModel;
    private subs = new SubSink();

    get userRoles(): UserRoleModel {
        return this.authService.currentUser.userRoles;
    }

    constructor(private readonly ticketHttpService: TicketHttpService,
                private readonly ticketService: TicketService,
                private readonly currentTicketService: CurrentTicketService,
                private readonly boardService: BoardHttpService,
                private readonly authService: AuthenticationService,
                private readonly translate: TranslateService,
                private readonly templateHttpService: TicketTemplateHttpService,
                private readonly templateService: TicketTemplateService,
                private readonly location: Location,
                private readonly router: Router,
                private readonly snackbarService: SnackbarService,
                private readonly dialog: MatDialog,
                private readonly dialogService: DialogService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ CRUD
    // -----------------------------------------------------------------------------------------------------

    load(key: string, templateMode = false): void {
        const state = new TicketDetailState();
        state.isLoading = true;
        this.state.next(state);

        if (!key) {
            this.ticket.next(this.createDefaultTicket());
            this.template.next(new TicketTemplateModel());
            this.title.next(templateMode ? 'Neue Vorlage erstellen' : 'Neues Ticket erstellen');
            this.state.next({...state, ...this.assignTicketPermissionsToState(state), templateMode, editMode: true, isLoading: false});
            return;
        }

        if (templateMode) {
            state.templateMode = true;
            this.loadTemplate(Number.parseInt(key, 10), state);
        } else {
            state.templateMode = false;
            this.loadTicket(key, state);
        }
    }

    private loadTicket(ticketKey: string, state: TicketDetailState): void {
        this.subs.sink = this.ticketHttpService.getByTicketId(ticketKey)
            .pipe(map((ticket) => {
                ticket.description = ticket.description.replace(/(\r\n|\r|\n){2,}/g, '$1\n');
                return ticket;
            }))
            .subscribe({
                next: (ticket) => {
                    this.currentTicket = ticket;
                    this.state.next({
                        ...this.currentState,
                        ...this.assignTicketPermissionsToState(state),
                        isLoading: false,
                        creationMode: false
                    });
                    this.listenToCurrentTicketChanges();
                    this.setTicketTitle(ticket);
                },
                error: () => this.onError()
            });
    }

    private listenToCurrentTicketChanges(): void {
        this.currentTicketService.currentTicketInfo.subscribe((currentTicketInfo) => this.state.next({
            ...this.currentState,
            isCurrentTicket: currentTicketInfo.currentTicketId === this.currentTicket.id
        }));
    }

    create(ticket: TicketModel, boardsToLink: number[]): void {
        if (this.currentState.templateMode) {
            this.createTemplate(ticket);
        } else {
            this.createTicket(ticket, boardsToLink);
        }
    }

    update(ticket: TicketModel, boardsToLink: number[]): void {
        if (this.currentState.templateMode) {
            this.updateTemplate(ticket);
        } else {
            this.updateTicket(ticket, boardsToLink);
        }
    }

    saveAsTemplate(ticket: TicketModel): void {
        if (!this.validateTicket(ticket)) {
            return;
        }

        const dialogRef = this.dialog.open(TicketTemplateNameDialogComponent, {
            width: '420px',
            panelClass: ['mat-dialog-overflow', 'dialog-0-p']
        });

        this.subs.sink = dialogRef.afterClosed().subscribe((name: string) => {
            if (stringIsNullOrWhiteSpace(name)) {
                return;
            }

            const template = this.templateService.mapTicketToTemplate(ticket, new TicketTemplateModel());
            template.name = name;
            this.template.next(template);
            this.state.next({...this.currentState, templateMode: true});
            this.createTemplate(ticket);
        });
    }

    cloneTicket(ticket: TicketModel): void {
        const state = this.currentState;
        this.state.next({
            ...this.state.value,
            ...state,
            canCancelEditMode: false,
            creationMode: true,
            canDelete: false,
            editMode: true
        });
        this.currentTicket = this.ticketService.cloneTicket(ticket, ticket.toDos);
        this.location.replaceState(`../ticket/create`);
    }

    reset(): void {
        this.title.next('Ticketdetail');
        this.subs.unsubscribe();
        this.ticket.next(null);
        this.tempTicket = null;
        this.state.next(new TicketDetailState());
    }

    createDefaultTicket(): TicketModel {
        return {
            ...new TicketModel(),
            description: '',
            ticketType: new TicketTypeModel(),
            ticketState: {id: 1, name: 'Offen'},
            priority: DEFAULT_PRIORITY,
            priorityId: DEFAULT_PRIORITY.id,
            creator: this.authService.currentUser
        };
    }

    startCurrentTicket(): void {
        this.currentTicketService.switchTicket(this.currentTicket.id).subscribe();
    }

    stopCurrentTicket(): void {
        const data = {...new TicketSwitchStopDialogData(), ticketId: this.currentTicket.id, ticketName: this.currentTicket.summary};
        this.dialogService.openTicketSwitchStopDialog(data);
    }

    pauseCurrentTicket(): void {
        this.currentTicketService.pauseTicket().then();
    }

    private createTicket(ticket: TicketModel, boardsToLink: number[]): void {
        if (!this.validateTicket(ticket)) {
            return;
        }

        this.setSavingState();
        this.subs.sink = this.ticketHttpService.createV2(ticket)
            .subscribe({
                next: (createdTicket) => {
                    this.setTicketTitle(createdTicket);
                    this.onUpdate();
                    this.load(createdTicket.ticketKey);
                    this.linkToBoards(createdTicket.id, boardsToLink);
                    this.location.replaceState(`../ticket/${createdTicket.ticketKey}`);
                },
                error: () => this.setSavingState(false)
            });
    }

    private updateTicket(ticket: TicketModel, boardsToLink: number[]): void {
        this.setSavingState();

        this.subs.sink = this.ticketHttpService.update(ticket)
            .subscribe({
                next: () => {
                    this.onUpdate();
                    this.setTicketTitle(ticket);
                    this.ticket.next({...ticket});
                    this.linkToBoards(ticket.id, boardsToLink);
                }, error: () => {
                    this.ticket.next({...ticket, ...this.tempTicket});
                    this.setSavingState(false);
                }
            });
    }


    private loadTemplate(templateId: number, state: TicketDetailState): void {
        this.subs.sink = this.templateHttpService.getTicketTemplateById(templateId).subscribe({
            next: (template) => {
                this.template.next(template);
                this.ticket.next(this.templateService.mapTemplateToTicket(template));
                this.title.next('Vorlage bearbeiten');
                state = {
                    ...this.currentState,
                    ...this.assignTicketPermissionsToState(state),
                    isLoading: false,
                    creationMode: false
                };
                this.state.next({...this.currentState, ...state});
            },
            error: () => this.onError()
        });
    }

    private createTemplate(ticket: TicketModel): void {
        this.setSavingState();
        const template = this.templateService.mapTicketToTemplate(ticket, this.template.value);

        this.subs.sink = this.templateHttpService.createTicketTemplate(new TicketTemplateCreateRequest(
            template.name,
            template.summary,
            template.description,
            template.externalDescription,
            template.ticketType?.id,
            template.project?.id,
            template.customer?.id,
            template.customerRef?.id,
            template.continuous,
            template.priority?.id,
            template.assignee?.id,
            template.projectTemplateId,
            template.milestoneTemplateId,
            template.estimatedTime,
            template.maximumTime,
            template.deductionDuration,
            template.maximumBudget,
            template.ticketToDos,
            template.boardIds
        )).subscribe({
            next: (createdTemplate) => {
                this.template.next(createdTemplate);
                this.load(createdTemplate.id.toString(), true);
                this.location.replaceState(`../ticket/template/${createdTemplate.id}`);
                this.onUpdate();
            }, error: () => this.state.next({...this.currentState, isSaving: false})
        });
    }

    private updateTemplate(ticket: TicketModel): void {
        this.setSavingState();
        const template = this.templateService.mapTicketToTemplate(ticket, this.template.value);
        this.subs.sink = this.templateHttpService.updateTicketTemplate(new TicketTemplateUpdateRequest(
            template.id,
            template.name,
            template.summary,
            template.description,
            template.externalDescription,
            template.ticketType?.id,
            template.project?.id,
            template.customer?.id,
            template.customerRef?.id,
            template.continuous,
            template.priority?.id,
            template.assignee?.id,
            template.projectTemplateId,
            template.milestoneTemplateId,
            template.estimatedTime,
            template.maximumTime,
            template.deductionDuration,
            template.maximumBudget,
            template.ticketToDos,
            template.boardIds
        )).subscribe(() => this.onUpdate());
    }

    private linkToBoards(ticketId: number, boardsToLink: number[]): void {
        if (!boardsToLink) {
            return;
        }

        this.subs.sink = this.boardService.upsertTicketToBoards({ticketId, boardIds: boardsToLink, boardStateId: null})
            .subscribe(() => this.ticket.next(this.currentTicket));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Data
    // -----------------------------------------------------------------------------------------------------

    private assignTicketPermissionsToState(state: TicketDetailState): TicketDetailState {
        state.canDelete = this.userCanDelete();
        state.isCurrentTicket = this.isCurrentTicket();
        state.canCancelEditMode = this.userCanCancelEditMode();
        state.canLinkBoards = !state.templateMode && this.authService.currentUser.userRoles.ticketBoards;
        state.canSeeDeductionDuration = this.authService.currentUser.userRoles.ticketsAbrechnungszeitAnzeigen;
        return state;
    }

    private validateTicket(ticket: TicketModel): boolean {
        const state = this.currentState;

        // Summary
        if (!ticket.summary) {
            this.snackbarService.open(this.translate.instant('TICKET.HEADLINE_MISSING'));
            return false;
        }

        // Description
        if (!ticket.description) {
            this.snackbarService.open(this.translate.instant('TICKET.DESCRIPTION_MISSING'));
            return false;
        }

        // Customer required
        if (!ticket.customer) {
            this.snackbarService.open(this.translate.instant('TICKET.CUSTOMER_MUST_BE_SELECTED'));
            return false;
        }

        // Responsible required unless it's a template
        if (!state.templateMode && !ticket.assignee) {
            this.snackbarService.open(this.translate.instant('TICKET.RESPONSIBLE_PERSON_MUST_BE_SELECTED'));
            return false;
        }

        return true;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ View / Helper
    // -----------------------------------------------------------------------------------------------------

    toggleEditMode(): void {
        this.state.next({...this.currentState, editMode: !this.currentState.editMode});
    }

    private setSavingState(saving = true): void {
        const state = this.currentState;
        state.isSaving = saving;
        this.state.next({...this.currentState, ...state});
    }

    private onUpdate(): void {
        this.state.next({...this.currentState, isSaving: false, editMode: false});
    }

    private setTicketTitle(ticket: TicketModel): void {
        this.title.next(ticket.ticketKey + ' ' + ticket.summary);
    }

    private userCanCancelEditMode(): boolean {
        const state = this.currentState;
        const isExistingTemplate = state.templateMode && this.template.value != null;
        const isExistingTicket = this.currentTicket.id != null;

        return this.userRoles.ticketsBearbeiten && (isExistingTemplate || isExistingTicket);
    }

    private userCanDelete(): boolean {
        return this.authService.currentUser.userRoles.ticketsLoeschen && this.currentTicket?.id != null;
    }

    private isCurrentTicket(): boolean {
        return this.currentTicketService.currentTicketInfo.getValue()?.currentTicketId === this.currentTicket?.id;
    }

    private onError = (): Promise<boolean> => this.router.navigate(['/ticket']);
}
